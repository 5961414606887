<script setup>
import { computed, ref } from 'vue'
import { t } from '@/i18n'
import { registerShowRef, registerModel } from '@/model/user'
import { appNameRef } from '@/model/basic'
import WiPopup from '@/components/WiPopup.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const { phoneCodeRef, dataRef, registerFunc} = registerModel()

const appNameArr = computed(()=> {
    return appNameRef.value.split(' ')
})
let agree = ref(false)

function toPage(path) {
    registerShowRef.value = false
    router.push(path)
}
</script>

<template>
    <WiPopup v-model="registerShowRef" center width="420px">
        <div class="w-full bg-default text-default rounded-xl" style="box-shadow: 0px 0px 0px 7px rgba(255,255,255,0.3);">
            <div class="p-4 lg:px-8 flex items-center">
                <div class="text-minor flex-1 overflow-hidden">
                    <svg class="w-4 h-4 inline-block align-middle" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z"></path>
                    </svg>
                    <b class="ml-2 text-sm align-middle inline-block">{{t('注册')}}</b>
                </div>
                <button @click="registerShowRef = false" class="w-8 h-8 ctx-minor rounded-full flex items-center justify-center">
                    <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
                    </svg>
                </button>
            </div>
            <div class="p-4 lg:px-8">
                <h6 class="mb-8 text-xl font-bold">
                    <span class="mr-2 inline-block">{{t('注册2')}}</span>
                    <template v-for="(item,index) in appNameArr" :key="index">
                        <span :class="index==0?'text-minor':'text-third'" class="mr-1 font-mont inline-block">{{item}}</span>
                    </template>
                </h6>
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('手机')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="text" v-model="dataRef.mobile" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('密码')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="password" v-model="dataRef.password" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('确认密码')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="password" v-model="dataRef.password_repetition" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('邀请码')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="text" v-model="dataRef.invitation_code" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <div class="pb-5 text-sm">
                    <div @click="agree = !agree" class="inline-block align-middle cursor-pointer select-none">
                        <span class="mr-2 inline-block align-middle">
                            <em :class="agree?'ctx-theme':'border border-default'" class="w-5 h-5 flex items-center justify-center">
                                <svg v-if="agree" class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
                                </svg>
                            </em>
                        </span>
                        <span class="align-middle">{{t('我已阅读并同意')}}</span>
                    </div>
                    <a href="javascript:;" @click="toPage('/registerLicense')" class="align-middle text-theme underline">&nbsp;{{t('注册协议')}}</a>
                </div>
                <button @click="registerFunc()" :disabled="!agree" class="px-9 h-10 max-w-full mx-auto ctx-theme text-sm rounded font-medium block">
                    <span>{{t('注册')}}</span>
                </button>
            </div>
        </div>
    </WiPopup>
</template>