<script setup>
import { computed, ref, watch } from "vue";
import { t, fn, langRef, languageList, setLanguage } from '@/i18n'
import { useRoute } from "vue-router";
import { scrollToTop } from '@/common/core'
import { userModel, loginShowRef, registerShowRef, logoutFunc } from '@/model/user'
import { messageNoReadCountRef, messageModel } from '@/model/article'
import { configMOdel } from '@/model/basic'

import UserLogin from "./pages/user/UserLogin.vue";
import UserRegister from "./pages/user/UserRegister.vue";

const { messageListRef, messageListFunc } = messageModel(true)

const { toCustomerURLFunc } = configMOdel(true)

const route = useRoute()

const currentPath = computed(()=> {
    return route.path
})

const { memberRef } = userModel()

const nav_list = ref([
    {title: t('首页'), path: '/'},
    {title: t('关于'), path: '/about'},
    {title: t('团队与条件'), path: '/license'},
    {title: t('推荐计划'), path: '/referral_program'},
    {title: 'FAQs', path: '/FAQs'},
])
let nav_show = ref(false)

const mine_list = ref([
    {title: t('个人'), path: '/profile'},
    {title: t('提现'), path: '/withdraw'},
    {title: t('会员'), path: '/membership'},
    // {title: t('推荐'), path: '/referral'},
    {title: t('搜索酒店'), path: '/search_hotel'},
    {title: t('酒店历史'), path: '/hotel_history'},
    {title: t('事件'), path: '/event'},
    {title: t('账户明细'), path: '/account_logs'},
    {title: t('代理支持'), func: ()=> toCustomerURLFunc()},
    {title: t('登出'), func: ()=> logout()},
])
let mine_show = ref(false)
watch(() => route.path, (newX)=> {
    nav_show.value = false
    mine_show.value = false
})

function changeLanguage() {
    setLanguage({value: langRef.value})
}
function logout() {
    mine_show.value = false
    logoutFunc()
}
</script>

<template>
    <div>
        <em class="w-full h-[110px] block"></em>
        <header id="publicheader" class="w-full ctx-theme text-sm fixed left-0 top-0 z-30">
            <section class="w-full border-b border-black/20 block">
                <div class="ctx-wrap h-[60px] flex items-center justify-between">
                    <img src="../assets/img/logo.png" class="w-auto h-12 block">
                    <div class="h-full flex items-center">
                        <div v-if="memberRef&&memberRef.id" class="mr-10 hidden lg:block cursor-pointer hover:opacity-70">
                            <span>{{fn(memberRef.account&&memberRef.account.user_money)}}</span>
                        </div>
                        <template v-else>
                            <button @click="loginShowRef = true" class="h-full px-4 border-l border-black/20 text-minor hover:text-third hidden lg:flex lg:items-center">
                                <svg class="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M4 15H6V20H18V4H6V9H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V15ZM10 11V8L15 12L10 16V13H2V11H10Z"></path>
                                </svg>
                                <span class="ml-1">{{t('登录')}}</span>
                            </button>
                            <button @click="registerShowRef = true" class="h-full px-4 ctx-minor hover:bg-third hidden lg:flex lg:items-center">
                                <svg class="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z"></path>
                                </svg>
                                <span class="ml-1">{{t('注册')}}</span>
                            </button>
                        </template>
                        <div class="h-full pl-4 flex items-center relative">
                            <b class="mr-1 text-sm">{{t('title')}}</b>
                            <IconArrowDownS class="w-5 h-5 text-minor" />
                            <select @change="changeLanguage" v-model="langRef" class="w-full h-full bg-white text-black absolute left-0 top-0 z-10 opacity-0">
                                <option v-for="(item,index) in languageList" :key="index" :value="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <router-link to="/message" class="h-full pl-4 flex items-center">
                            <div class="relative">
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M20 17H22V19H2V17H4V10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17ZM18 17V10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10V17H18ZM9 21H15V23H9V21Z"></path>
                                </svg>
                                <i v-if="messageNoReadCountRef>0" class="w-2 h-2 bg-red-500 rounded-full flex absolute top-0 right-0"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
            </section>
            <section>
                <div class="ctx-wrap h-[50px] flex items-center justify-between">
                    <div class="h-full flex items-center">
                        <button @click="nav_show = !nav_show" class="w-8 h-8 ctx-minor rounded-sm lg:hidden flex items-center justify-center">
                            <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
                            </svg>
                        </button>
                        <div class="h-full hidden lg:flex lg:items-center">
                            <router-link to="/" class="w-[50px] h-full text-minor hover:text-third border-l border-r border-black/20 flex items-center justify-center">
                                <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M20 20.0001C20 20.5524 19.5523 21.0001 19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001ZM9 10.0001V16.0001H15V10.0001H9ZM11 12.0001H13V14.0001H11V12.0001Z"></path>
                                </svg>
                            </router-link>
                            <nav class="h-full ml-3 flex items-center">
                                <router-link 
                                :to="item.path" v-for="(item,index) in nav_list" :key="index" 
                                :class="item.path == currentPath ? 'text-third' : ''" 
                                class="px-3 h-full hover:text-third flex items-center">
                                    <b>{{item.title}}</b>
                                </router-link>
                            </nav>
                        </div>
                    </div>
                    <div v-if="memberRef&&memberRef.id" @click="mine_show = !mine_show" class="cursor-pointer hover:text-third select-none flex items-center">
                        <img>
                        <WiImage v-if="memberRef.head_portrait" :src="memberRef.head_portrait" fit="cover" class="w-8 h-8 rounded-full overflow-hidden" />
                        <img v-else src="../assets/img/avatar.png" class="w-8 h-8 object-cover rounded-full block">
                        <b class="ml-2">{{memberRef.username}}</b>
                        <IconArrowDownS class="w-5 h-5 text-minor" />
                    </div>
                    <div v-else class="h-full flex items-center">
                        <button @click="loginShowRef = true" class="h-full px-4 border-l border-black/20 text-minor hover:text-third lg:hidden flex items-center">
                            <svg class="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M4 15H6V20H18V4H6V9H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V15ZM10 11V8L15 12L10 16V13H2V11H10Z"></path>
                            </svg>
                            <span class="ml-1">{{t('登录')}}</span>
                        </button>
                        <button @click="registerShowRef = true" class="h-full pl-4 border-l border-black/20 text-minor hover:text-third lg:hidden flex items-center">
                            <svg class="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z"></path>
                            </svg>
                            <span class="ml-1">{{t('注册')}}</span>
                        </button>
                    </div>
                </div>
            </section>
            <section class="ctx-wrap relative">
                <transition name="fadeInDown">
                    <ol v-show="mine_show" class="w-40 py-3 bg-default text-default duration-300 rounded-md shadow-md absolute right-0 top-0">
                        <li v-for="(item,index) in mine_list" :key="index">
                            <router-link v-if="item.path" :to="item.path" :class="item.path == currentPath ? 'text-third' : ''" class="px-4 py-2 hover:text-third block">
                                <b>{{item.title}}</b>
                            </router-link>
                            <div v-else @click="item.func" class="px-4 py-2 hover:text-third block cursor-pointer select-none">
                                <b>{{item.title}}</b>
                            </div>
                        </li>
                    </ol>
                </transition>
            </section>
            <section class="relative lg:hidden">
                <transition name="fadeInLeft">
                    <nav v-show="nav_show" class="w-52 py-3 bg-default text-default duration-300 shadow-md absolute left-0 top-0">
                        <router-link :to="item.path" v-for="(item,index) in nav_list" :key="index" :class="item.path == currentPath ? 'text-third' : ''" class="w-full py-3 px-8 block">
                            <b>{{item.title}}</b>
                        </router-link>
                    </nav>
                </transition>
            </section>
        </header>

        <!-- backtop -->
        <button 
        @click="scrollToTop()" 
        class="w-10 h-10 ctx-minor hover:bg-theme hover:text-minor rounded cursor-pointer fixed right-3 lg:right-5 bottom-32 z-40 flex items-center justify-center" 
        style="box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.2);">
            <IconArrowUpS class="w-5 h-5" />
        </button>

        <!-- login -->
        <UserLogin></UserLogin>

        <!-- register -->
        <UserRegister></UserRegister>
    </div>
</template>