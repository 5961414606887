export default {
    "title": "🇫🇷 Français",

    "首页": "Accueil",
    "关于": "À propos de",
    "会员": "Membre",
    "推荐计划": "Programme de recommandation",
    "个人": "Personnel",
    "推荐": "Recommander",
    "预订": "Réserver",
    "事件": "Événements",
    "登出": "Déconnexion",
    "登录": "Connexion",
    "注册": "S'inscrire",
    "我们的应用程序现在可用": "Notre application est désormais disponible",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Pour une meilleure expérience, nous venons de lancer notre application mobile pour des réservations sans couture et plus rapides.",
    "iOS版下载（即将推出）": "Téléchargement de la version iOS (à venir)",
    "Android版下载（即将推出）": "Téléchargement de la version Android (à venir)",
    "为什么选择我们": "Pourquoi nous choisir",
    "我们卓越的服务。": "Notre service d'excellence.",
    "收入最高的酒店": "Hôtels les plus rentables",
    "我们的合作酒店收入最高。": "Nos hôtels partenaires génèrent le plus de revenus.",
    "回报": "Retour sur investissement",
    "最具特色的酒店": "Hôtels les plus caractéristiques",
    "我们平台上最有特色的酒店。": "Les hôtels les plus distinctifs sur notre plateforme.",
    "热门国家和目的地": "Pays et destinations populaires",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Découvrez les meilleurs conseils de nos partenaires et amis du monde entier.",
    "酒店": "Hôtels",
    "预订酒店并开始赚取": "Réservez un hôtel et commencez à gagner",
    "让我们开始与TitanTraveler一起探索世界": "Commencez à explorer le monde avec TitanTraveler",
    "客户评价": "Évaluations des clients",
    "查看我们用户的真实评价。": "Consultez les avis réels de nos utilisateurs.",
    "银行卡列表": "Liste des cartes bancaires",

    "银行列表": "Liste des banques",
    "持卡人姓名": "Nom du titulaire de la carte",
    "银行卡号": "Numéro de carte bancaire",
    "支付密码": "Mot de passe de paiement",
    "提交": "Soumettre",
    "Pix列表": "Liste Pix",
    "Pix类型": "Type de Pix",
    "Pix账号": "Compte Pix",
    "账号列表": "Liste des comptes",
    "网络": "Réseau",
    "地址": "Adresse",
    "备注": "Remarque",
    "充值金额": "Montant de recharge",
    "支付类型": "Type de paiement",
    "支付银行": "Banque de paiement",
    "USDT地址": "Adresse USDT",
    "二维码": "Code QR",
    "充值渠道": "Canal de recharge",
    "充值凭证": "Preuve de recharge",
    "立即登录": "Connexion immédiate",
    "用户名": "Nom d'utilisateur",
    "密码": "Mot de passe",
    "邀请码": "Code d'invitation",
    "确认密码": "Confirmer le mot de passe",
    "手机": "Téléphone",
    "注册2": "S'inscrire",
    "金额": "Montant",
    "新增": "Ajouter",

    "我们的精彩": "Notre excellence",
    "故事": "Histoire",
    "奖项": "Récompenses",
    "获得": "Obtenir",
    "钱包余额": "Solde du portefeuille",
    "今日奖励": "Récompenses du jour",
    "总行程": "Total des voyages",
    "访问行程": "Parcourir les voyages",
    "酒店历史": "Historique de l'hôtel",
    "单价": "Prix unitaire",
    "数量": "Quantité",
    "总额": "Montant total",
    "奖励": "Récompense",
    "上一页": "Page précédente",
    "下一页": "Page suivante",
    "每套预订量": "Quantité par réservation",
    "预订奖励": "Récompense de réservation",
    "每日预订": "Réservations quotidiennes",
    "手机号码": "Numéro de téléphone",
    "修改密码": "Modifier le mot de passe",
    "登录密码": "Mot de passe de connexion",
    "当前密码": "Mot de passe actuel",
    "新密码": "Nouveau mot de passe",
    "确认新密码": "Confirmer le nouveau mot de passe",
    "保存修改": "Enregistrer les modifications",
    "充值": "Recharge",
    "我们的": "Notre",
    "复制": "Copier",

    "分享链接": "Partager le lien",
    "搜索酒店": "Rechercher un hôtel",
    "实际等级": "Niveau réel",
    "升级": "Mettre à niveau",
    "已完成预订": "Réservations terminées",
    "登录时间": "Heure de connexion",
    "信誉分": "Score de crédit",
    "控制菜单": "Menu de contrôle",
    "提现": "Retrait",
    "提现账号": "Compte de retrait",
    "你确定要删除此账号吗": "Êtes-vous sûr de vouloir supprimer ce compte ?",
    "你确定要删除此地址吗": "Êtes-vous sûr de vouloir supprimer cette adresse ?",
    "提示": "Conseil",
    "是": "Oui",
    "否": "Non",
    "全部": "Tout",
    "待处理": "En attente",
    "冻结中": "Bloqué",
    "已完成": "Terminé",
    "订阅": "S'abonner",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Recevez des notifications lorsque nous lançons de nouveaux modèles ou mises à jour. Inscrivez-vous et nous vous enverrons des notifications par e-mail.",
    "我们的伙伴": "Nos partenaires",
    "关于我们": "À propos de nous",
    "实时聊天支持": "Support de chat en direct",
    "在线聊天": "Chat en ligne",
    "创建时间": "Temps de création",
    "你确定要登出当前账号吗？": "Êtes-vous sûr de vouloir vous déconnecter du compte actuel ?",
    "请输入你的邮箱": "Veuillez entrer votre adresse e-mail",
    "好评": "Bonne évaluation",

    "条评论": "Commentaires",
    "平均每晚": "Moyenne par nuit",
    "旅行旅程": "Voyage",
    "正在寻找酒店": "Recherche d'hôtel en cours",
    "开始预订": "Commencer la réservation",
    "旅程价格": "Prix du voyage",
    "佣金": "Commission",
    "底部-about": "Nous sommes une agence de marketing numérique basée en Allemagne qui permet aux entreprises de réussir dans le monde numérique grâce à des stratégies de marketing innovantes et axées sur les données, un service personnalisé et une mentalité de croissance qui garde le pouls des tendances de l'industrie. Que vous ayez besoin d'une agence de référencement, d'un concepteur de site Web, d'une gestion de paiement par clic, d'une stratégie de marque, de développement de contenu ou d'autres services de marketing numérique, Dertour Group possède l'expertise et l'expérience pour vous aider à atteindre vos objectifs dans un monde axé sur les résultats.",
    "立即预订": "Réserver maintenant",
    "搜索": "Rechercher",
    "快乐的客户": "Clients satisfaits",
    "快乐的酒店老板": "Propriétaires d'hôtels heureux",
    "评论1": "Architecture sur les bâtiments.",
    "评论2": "C'est un endroit idéal pour se promener avec des rues étroites et une belle architecture sur les bâtiments.",
    "评论3": "Belle place entre la plage et l'avenue centrale.",
    "评论4": "Très beaux bâtiments avec une histoire intéressante.",
    "评论5": "Beaucoup de restaurants au choix et une atmosphère très détendue.",
    "评论6": "Belle terre avec de charmants vergers d'orangers et des animaux.",
    "评论7": "Endroit idéal pour une escapade de week-end.",
    "评论8": "Endroit intéressant...",
    "评论9": "Un endroit idéal pour s'asseoir et regarder le monde passer.",
    "评论10": "Club de plage haut de gamme qui est un bel hommage à ceux qui sont morts pendant la guerre.",
    "评论11": "Il y a tellement de choses à voir et c'est un endroit idéal pour les vacances et la détente!",
    "评论12": "Première fois ici et c'était très agréable, je reviendrai la prochaine fois.",
    "评论13": "C'était une expérience différente et c'était génial, les locaux étaient très sympathiques et polis!",
    "推荐计划1": "Les utilisateurs de la plateforme peuvent inviter d'autres personnes à devenir des agents de la plateforme en utilisant un code de parrainage, qui deviennent alors vos filleuls.",
    "推荐计划2": "En tant que parrain, vous pouvez récupérer un certain pourcentage des bénéfices de vos filleuls, les bénéfices du parrain étant directement retournés sur son compte de la plateforme ou répercutés sur les rapports d'équipe.",
    "推荐计划3": "Remarque : Tous les agents/filleuls de la plateforme recevront un pourcentage de bénéfices et de récompenses correspondant, la constitution d'une équipe n'affectant pas les bénéfices et les récompenses des agents ou filleuls.",
    "评分": "Score",
    "评论": "Commentaires",
    "酒店很棒": "Superbe hôtel",
    "终于体验了，酒店非常好": "J'ai finalement eu l'expérience, l'hôtel est excellent",
    "我对酒店非常满意并推荐预订": "Je suis très satisfait de l'hôtel et je recommande la réservation",
    "我很高兴很满意，价格实惠": "Je suis très content et satisfait, les prix sont abordables",
    "服务非常好，我非常喜欢": "Le service est excellent, j'adore",
    "体验很不错，朋友已经第二次预订了": "Une expérience formidable, mes amis ont déjà réservé pour la deuxième fois",
    "酒店环境优美": "Cadre magnifique de l'hôtel",
    "酒店卫生干净": "Propreté impeccable de l'hôtel",
    "工作人员态度非常好": "Personnel très aimable",
    "酒店服务很棒": "Services de l'hôtel excellents",
    "你还没有设置支付密码，请前往设置": "Vous n'avez pas encore défini de mot de passe de paiement, veuillez le configurer",
    "订单": "Commande",
    "复制成功": "Copié avec succès",
    "团队与条件": "Equipe et conditions",
    "站内信": "Messages du site",
    "请联系客服升级会员": "Veuillez contacter le service client pour mettre à niveau votre abonnement",
    "我已阅读并同意": "J'ai lu et accepté",
    "注册协议": "Accord d'enregistrement",
    "忘记密码": "Oublier le mot de passe",
    "账户明细": "Détails du compte",
    "时间": "Heure",
    "类型": "Type",
    "代理支持": "Assistance aux agents",
    "冻结金额": "Montant gelé",
}